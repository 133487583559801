// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withchangeBackgroundColor } from "../codeFile/M_5.tsx";
import * as localizedValues from "./Dn3rSmfiQ-0.js";
const RichTextWithchangeBackgroundColor = withchangeBackgroundColor(RichText);

const cycleOrder = ["ye_7f5Da3"];

const variantClassNames = {ye_7f5Da3: "framer-v-9v8n9l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, hr475DNaJ: title ?? props.hr475DNaJ ?? "-Dizajn pre digitálny vek-", nCorHX5I_: tap ?? props.nCorHX5I_} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hr475DNaJ, nCorHX5I_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ye_7f5Da3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1is9pl3 = activeVariantCallback(async (...args) => {
if (nCorHX5I_) {
const res = await nCorHX5I_(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vFMFW", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-9v8n9l", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ye_7f5Da3"} onTap={onTap1is9pl3} ref={ref} style={{...style}}><RichTextWithchangeBackgroundColor __fromCanvasComponent children={getLocalizedValue("value0", activeLocale) ?? <React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItcmVndWxhcg==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-letter-spacing": "9.70745px", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}><motion.span style={{"--framer-font-size": "20.0982px"}}>-Dizajn pre digitálny vek-</motion.span></motion.p></React.Fragment>} className={"framer-1lvj352"} data-framer-name={"Podnadpis"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"B_AuTP5t7"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={hr475DNaJ} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vFMFW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vFMFW .framer-hcw6zf { display: block; }", ".framer-vFMFW .framer-9v8n9l { cursor: pointer; height: 30px; overflow: hidden; position: relative; width: 571px; }", ".framer-vFMFW .framer-1lvj352 { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 571
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"hr475DNaJ":"title","nCorHX5I_":"tap"}
 * @framerImmutableVariables false
 */
const FramerDn3rSmfiQ: React.ComponentType<Props> = withCSS(Component, css, "framer-vFMFW") as typeof Component;
export default FramerDn3rSmfiQ;

FramerDn3rSmfiQ.displayName = "m";

FramerDn3rSmfiQ.defaultProps = {height: 30, width: 571};

addPropertyControls(FramerDn3rSmfiQ, {hr475DNaJ: {defaultValue: "-Dizajn pre digitálny vek-", displayTextArea: false, title: "Title", type: ControlType.String}, nCorHX5I_: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerDn3rSmfiQ, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Dn3rSmfiQ:default", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf", weight: "400"}])